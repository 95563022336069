import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import { getSrc } from "@/game-val/guides-utils.mjs";
import VideoModal from "@/game-val/VideoModal.jsx";
import ContentContainer from "@/shared/ContentContainer.jsx";
import More from "@/shared/More.jsx";

const Grid = styled("div")`
  display: grid;
  grid-gap: var(--sp-6) var(--sp-4);
  margin-bottom: var(--sp-6);
  grid-template-columns: repeat(3, 1fr);
  ${tablet} {
    grid-template-columns: 50% 50%;
  }
  ${mobile} {
    grid-template-columns: 100%;
  }
`;

const FilterBarWrapper = styled("div")`
  position: sticky;
  width: 100%;
  max-width: var(--sp-container);
  display: flex;
  flex-direction: column;
  gap: var(--sp-2);
  padding-block: var(--sp-2);
`;

const GuidesCount = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    color: var(--shade3);
    margin-left: auto;
    margin-bottom: var(--sp-4);
  }
`;

export const Tags = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--sp-2);
`;

export const Tag = styled("div")`
  display: flex;
  gap: var(--sp-1);
  align-items: center;
  color: var(--shade1);
  background-color: var(--shade7);
  border-radius: var(--br-sm);
  padding: 0 var(--sp-2);
  margin-bottom: var(--sp-1);

  &:not(:last-child) {
    margin-right: var(--sp-1);
  }

  img {
    height: 20px;
  }

  svg {
    width: var(--sp-2);
    margin-right: var(--sp-1);
  }
`;

const GuidesContainer = ({ guides, renderItem, type, renderModalFooter }) => {
  const [showModal, setShowModal] = useState(false);

  const [guide, setGuide] = useState({});

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const showGuide = useCallback(
    (id) => {
      setGuide(guides.find((g) => g.id === id));
      setShowModal(true);
    },
    [guides],
  );

  const src = getSrc(guide, type);
  return (
    <>
      <Grid>{guides?.map((item) => renderItem(item, showGuide))}</Grid>
      {src && (
        <VideoModal
          src={src}
          onModalClose={onModalClose}
          showModal={showModal}
          footer={renderModalFooter(guide)}
        />
      )}
    </>
  );
};

export default function GuidesLayout({
  filters,
  count,
  viewMoreCondition,
  onViewMore,
  guides,
  renderItem,
  renderModalFooter,
  type,
}) {
  const { t } = useTranslation();
  return (
    <>
      <FilterBarWrapper>{filters}</FilterBarWrapper>
      <ContentContainer>
        <GuidesCount>
          <span className="type-subtitle2">
            {t("val:guides.totalTips", "{{count, number}} Tips Total", {
              count,
            })}
          </span>
        </GuidesCount>
        <GuidesContainer
          type={type}
          guides={guides}
          renderItem={renderItem}
          renderModalFooter={renderModalFooter}
        />
        {viewMoreCondition && <More onClick={onViewMore} />}
      </ContentContainer>
    </>
  );
}
