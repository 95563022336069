import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";

type MoreProps = {
  onClick: () => void;
};

export default function More({ onClick }: MoreProps) {
  const { t } = useTranslation();
  return (
    <ButtonGroup block>
      <Button onClick={onClick}>{t("common:more", "More")}</Button>
    </ButtonGroup>
  );
}
